<template>
  <div>
    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Bridge Year Scholarships</h1>
          <p class="subtitle">
            Note, You must complete each leg to progress to the next. The first
            drop point is March, the second in June, and the last in July.
          </p>
        </div>
      </div>
    </section>

    <div class="timeline container">
      <div
        v-for="event in timeline"
        :key="event.time"
        class="columns is-mobile"
      >
        <div class="column is-6 time-box">
          <h3 class="title is-4">
            {{ event.time }}
          </h3>
        </div>
        <div class="column is-6 event-box">
          <p class="title is-5">
            {{ event.event }}
          </p>
          <div v-if="event.details">{{ event.details }}</div>
        </div>
      </div>
    </div>
    <div class="gains">
      <h3 class="title">Your Potential Resume Gains</h3>

      <div class="button">1 6-month Internship with Career Bridge</div>
      <div class="button">
        1 6-month startup engineering experience with the startup MVP
      </div>

      <div class="button">2 frameworks - VueJS and Bulma</div>
      <div class="button">1 portfolio MVP project</div>
      <div class="button">Up to 5 peer recommendations</div>
      <div class="button">Up to 1 industry pro recommendations</div>
    </div>
    <div class="purchase container" v-show="false">
      <h3 class="title">Experiences</h3>
      <p class="subtitle">Yo</p>
      <div class="columns is-multilined">
        <div
          v-for="product in products"
          :key="product.title"
          class="column is-4"
        >
          <div class="card">
            <div class="card-title title is-5">
              {{ product.title }}
            </div>
            <div class="card-body">
              {{ product.details }}
              <ul v-if="product.benefits">
                <li v-for="benefit in product.benefits" :key="benefit.title">
                  {{ benefit.title }}
                </li>
              </ul>
              {{ product.price }}
            </div>

            <div class="card-footer">
              Register
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../_variables.scss";

.gains {
  div {
    margin: 1rem 0 0 1rem;
  }
}
.title,
.subtitle {
  color: $white;
}

.timeline {
  .columns {
    padding: 0 4.2rem;
  }
  .time-box {
    text-align: right;
    padding: 2rem 1rem;
  }
  .event-box {
    text-align: left;
    padding: 2rem 0;
  }
}

.hero.is-primary.is-bold {
  background: $primary-dark;
  background-image: none;
}

.hero.is-primary .title {
  color: $primary-light;
}
</style>

<script>
export default {
  name: "BridgeYear",
  props: {
    msg: String,
  },
  data() {
    return {
      timeline: [
        {
          time: "January",
          event: "Squad Introductions and VueJS Pathway",
          details: "Meet group members and learn vuejs via a curated pathway.",
        },
        {
          time: "February",
          event: "Practice VueJS",
          details:
            "Complete vueJS practice projects and prepare for a group project",
        },
        {
          time: "March - May",
          event: "VueJS Collab Project ",
          details:
            "Build small pieces of the Career Bridge app in your introduction to group collaboration",
        },
        {
          time: "June - July",
          event: "ReactJS pathway",
          details:
            "Picking up ReactJS will be a lot easier with a foundation in VueJS",
        },
        {
          time: "July - September/December",
          event: "ReactJS MVP Project ",
          details:
            "Collab and build out an MVP project, capping off your Bridge Year",
        },
      ],
      products: [
        {
          title: "Scholarship",
          details:
            "A sponsored open learning experience, specialized for 2020 Reskill Challenge Winners",
          price: "$0",
          benefits: [
            {
              title: "Self Learning Pathways",
            },

            {
              title: "Collaborative Project",
            },

            {
              title: "Access to private slack channels with industry mentors",
            },

            {
              title: "Sit-In Access to Workshops",
            },
          ],
        },
        {
          title: "Private Coach",
          details: "Learn faster and get your questions answered with a coach",

          benefits: [
            {
              title: "Monthly Advisory and Goal setting",
            },

            {
              title: "Question Pad Responses",
            },

            {
              title: "10% discounts on coach Workshops",
            },
          ],
          price: "$100/month",
        },
        {
          title: "Private Trainer",
          details:
            "Sharpen your skills even faster training with a private industry pro",
          benefits: [
            {
              title: "Weekly Code Review",
            },
            {
              title: "Weekly Advisory and Goal setting",
            },

            {
              title: "Question Pad Chat access",
            },

            {
              title: "10% discounts on coach Workshops",
            },
          ],
          price: "$1000/month",
        },
      ],
    };
  },
};
</script>
